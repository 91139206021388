import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { OptionChoice, Question } from "../types";
import Dropdown from "./inputTypes/Dropdown";
import Slider from "./inputTypes/Slider";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function SurveyResponse(props: {
  optionChoices: Array<OptionChoice>;
  question: Question;
  selectedOptionId: number | undefined;
  setSelectedOption: (optionChoiceId: number) => void;
}) {

  return (
    <div className="flex items-center justify-center flex-col">
      <span className="my-6 block text-2xl text-center leading-8 font-semibold tracking-normal text-zinc-600 sm:text-4xl">
        {props.question.question_title}
      </span>
      <div className="w-full sm:w-2/3 m-7 z-40">
          
          {/* Select Input Type */}
          {props.question.input_type_id === 1 && (
            <div className={`grid grid-cols-1 gap-3 lg:flex lg:justify-evenly lg:items-stretch basis-0 text-center`}> 
              {props.optionChoices.map((optionChoice: OptionChoice) => (
                <button
                  key={optionChoice.option_choice_id}
                  className={`${
                    props.selectedOptionId === optionChoice.option_choice_id
                      ? "bg-strata-blue text-white border-transparent"
                      : "bg-white border-gray-200 text-zinc-600 hover:bg-gray-50"
                  }
                  items-center rounded-md border border-gray-300 px-6 py-3 text-base font-medium shadow-sm sm:mt-4 flex-grow basis-0`}
                  onClick={() => {
                    props.setSelectedOption(optionChoice.option_choice_id);
                  }}
                >
                  {optionChoice.option_choice_name}
                </button>
              ))}
            </div>
          )}

          {/* Slider Input */}
          {props.question.input_type_id === 2 && (
            <div>
              <Slider
                optionChoices={props.optionChoices}
                selectedOptionId={props.selectedOptionId}
                setSelectedOption={(optionChoice: OptionChoice) => {
                  props.setSelectedOption(optionChoice.option_choice_id);
                }}
              />
            </div>
          )}

          {/* Dropdown Input */}
          {props.question.input_type_id === 3 && (
            <Dropdown
              selectedOptionId={1} //todo
              dropDownItems={props.optionChoices}
              setSelectedOption={(optionChoice: OptionChoice) => {
                props.setSelectedOption(optionChoice.option_choice_id);
              }}
            />
          )}

      </div>
    </div>
  );
}
