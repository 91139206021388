import React from "react";
import { createContext } from "react";
import { isDate } from "util/types";
import { Response, Survey, User } from "../types";

export interface IContextActions {
  type: "updateResponse" | "updateSurvey" | "updateUser" | "setStartTime" | "setExpireTime" | "clearContext";
  payload: Response | Survey | User | Date | {};
}

export interface IContextState {
  user: User;
  responses: Response[];
  survey: Survey;
  startTime: Date;
  expireTime: Date;
}

export const initalSurveyContextState: IContextState = {
  user: {
    survey_key: "",
    age_id: 0,
    years_experience_id: 0,
    gender_id: 0,
  },
  responses: [],
  survey: {
    surveyInformation: {
      instructions: "",
      organization_id: 0,
      survey_id: 0,
      survey_key: "",
      survey_name: "",
      start_date: "",
      end_date: "",
      updated_at: "",
    },
    surveyQuestions: [],
  },
  startTime: new Date(),
  expireTime: new Date(0),
};

export const surveyContextReducer = (state: IContextState, action: IContextActions) => {
  switch (action.type) {
    case "updateUser":
      Object.assign(state.user, action.payload);
      return state;
    case "updateResponse":
      let actionPayload = action.payload as Response;
      let index = state.responses.findIndex((response: Response) => response.questionId === actionPayload.questionId);
      if (index === -1) {
        state.responses.push(actionPayload);
      } else {
        state.responses[index] = actionPayload;
      }
      return state;
    case "updateSurvey":
      return {
        ...state,
        survey: action.payload,
      };
    case "setStartTime":
      if (action.payload instanceof Date) {
        state.startTime = action.payload;
      }
      return state;
    case "setExpireTime":
      if (action.payload instanceof Date) {
        state.expireTime = action.payload;
      }
      return state;
    case "clearContext":
      state = {
        user: {
          survey_key: "",
          age_id: 0,
          years_experience_id: 0,
          gender_id: 0,
        },
        responses: [],
        survey: {
          surveyInformation: {
            instructions: "",
            organization_id: 0,
            survey_id: 0,
            survey_key: "",
            survey_name: "",
            start_date: "",
            end_date: "",
            updated_at: "",
          },
          surveyQuestions: [],
        },
        startTime: new Date(),
        expireTime: new Date(0),
      };
      return state;
    default:
      return state;
  }
};

export interface IContextProps {
  contextState: IContextState;
  contextDispatch: React.Dispatch<IContextActions>;
}

const SurveyContext = createContext<IContextProps>({
  contextState: initalSurveyContextState,
  contextDispatch: () => {},
});

export const SurveyContextConsumer = SurveyContext.Consumer;
export const SurveyContextProvider = SurveyContext.Provider;
export default SurveyContext;
