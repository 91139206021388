import { useState, useEffect, useContext } from "react";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { OptionChoice } from "../types";
import SurveyContext from "../context/surveyContext";
import Radio from "../components/inputTypes/Radio";
import { Formik, Form, ErrorMessage } from "formik";
import Timer from "../components/Timer";
import Spinner from "../components/Spinner";

export default function Demographic() {
  const { contextState, contextDispatch } = useContext(SurveyContext);

  const [gendersOptionChoices, setGendersOptionChoices] = useState<
    Array<OptionChoice>
  >([]);
  const [agesOptionChoices, setAgesOptionChoices] = useState<
    Array<OptionChoice>
  >([]);
  const [yearsExperienceOptionChoices, setYearsExperienceOptionChoices] =
    useState<Array<OptionChoice>>([]);

  const navigate = useNavigate();

  useEffect(() => {

    // This is to prevent users from going back to the disclaimer and home pages once they have started
    window.onpopstate = () => {
      let leave = window.confirm("Are you sure you navigate away? Your responses will not be saved.");
      if(leave) {
        contextDispatch({ type: "clearContext", payload: {} });
        window.onbeforeunload = null;
        window.onpopstate = null;
        navigate("/");
      } else {
        navigate("/start");
      };
    };

    if (contextState.survey?.surveyInformation?.survey_id === 0) {
      navigate("/");
    }

    async function getGenders() {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/survey/users/genders`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: contextState.user.survey_key,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            let optionChoices: Array<OptionChoice> = [];
            for (let i = 0; i < data.length; i++) {
              optionChoices.push({
                option_choice_id: data[i].gender_id,
                option_choice_name: data[i].gender_name,
                option_group_id: 0,
              });
            }
            setGendersOptionChoices(optionChoices);
          });
      } catch (err) {
        console.error(err);
      }
    }

    async function getAges() {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/survey/users/ages`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: contextState.user.survey_key,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            let optionChoices: Array<OptionChoice> = [];
            for (let i = 0; i < data.length; i++) {
              optionChoices.push({
                option_choice_id: data[i].age_id,
                option_choice_name: data[i].age_name,
                option_group_id: 0,
              });
            }
            setAgesOptionChoices(optionChoices);
          });
      } catch (err) {
        console.error(err);
      }
    }

    async function getYearsExperience() {
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/survey/users/yearsexperience`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: contextState.user.survey_key,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            let optionChoices: Array<OptionChoice> = [];
            for (let i = 0; i < data.length; i++) {
              optionChoices.push({
                option_choice_id: data[i].years_experience_id,
                option_choice_name: data[i].years_experience_name,
                option_group_id: 0,
              });
            }
            setYearsExperienceOptionChoices(optionChoices);
          });
      } catch (err) {
        console.error(err);
      }
    }

    getGenders();
    getAges();
    getYearsExperience();
  }, []);

  if (
    gendersOptionChoices.length === 0 &&
    agesOptionChoices.length === 0 &&
    yearsExperienceOptionChoices.length === 0
  ) {
    return <Spinner />;
  } else {
    return (
      <div className="bg-white">
        <div className="pb-16 bg-gradient-to-r from-strata-blue to-strata-dark-blue lg:pb-0 lg:z-10 lg:relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8">
            <div className="lg:m-0 lg:pl-8">
              <div className="mx-auto max-w-md sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none"></div>
            </div>
          </div>
        </div>
        <div className="max-w-full mx-auto py-12 sm:py-12 sm:px-2 lg:px-4">
          <div className="max-w-2xl mx-auto w-11/12 lg:max-w-none">
            <div className="max-w-3xl">
              <h2 className="font-semibold text-gray-500">Section 1</h2>
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                About you
              </p>
            </div>

            <div className="space-y-16 border-t border-gray-200 pt-6 mt-6 sm:pt-6 sm:mt-6">
              <div className="relative overflow-hidden ">
                {/* <SideDecorations /> */}
                <Formik
                  initialValues={{
                    yearsExperience: contextState.user.years_experience_id,
                    age: contextState.user.age_id,
                    gender: contextState.user.gender_id,
                  }}
                  validate={(values) => {
                    const errors: any = {};
                    if (!values.yearsExperience) {
                      errors.yearsExperience = "Please select one";
                    }
                    if (!values.age) {
                      errors.age = "Age is required";
                    }
                    if (!values.gender) {
                      errors.gender = "Gender is required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    navigate("/survey/1");
                  }}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <Form>
                      <div className="space-y-5 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                          <div className="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start sm:pt-5">
                            <label
                              htmlFor="yearsExperience"
                              className="block text-sm font-medium text-zinc-700 sm:mt-px sm:pt-2"
                            >
                              How many years have you been in your current role?
                            </label>
                            <Radio
                              title="yearsExperience"
                              selectItems={yearsExperienceOptionChoices}
                              selectedOptionId={
                                contextState.user.years_experience_id
                              }
                              setSelectedOption={(
                                optionChoice: OptionChoice
                              ) => {
                                setFieldValue(
                                  "yearsExperience",
                                  optionChoice.option_choice_name
                                );
                                contextDispatch({
                                  type: "updateUser",
                                  payload: {
                                    years_experience_id:
                                      optionChoice.option_choice_id,
                                  },
                                });
                              }}
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs mt-2"
                              name="yearsExperience"
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                          <div className="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start sm:pt-5">
                            <label
                              htmlFor="age"
                              className="block text-sm font-medium text-zinc-700 sm:mt-px sm:pt-2"
                            >
                              What is your age?
                            </label>
                            <Radio
                              title="age"
                              selectItems={agesOptionChoices}
                              selectedOptionId={contextState.user.age_id}
                              setSelectedOption={(
                                optionChoice: OptionChoice
                              ) => {
                                setFieldValue(
                                  "age",
                                  optionChoice.option_choice_name
                                );
                                contextDispatch({
                                  type: "updateUser",
                                  payload: {
                                    age_id: optionChoice.option_choice_id,
                                  },
                                });
                              }}
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs mt-2"
                              name="age"
                              component="div"
                            />
                          </div>
                          <div className="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start sm:pt-5">
                            <label
                              htmlFor="gender"
                              className="block text-sm font-medium text-zinc-700 sm:mt-px sm:pt-2"
                            >
                              What is your gender?
                            </label>
                            <Radio
                              title="gender"
                              selectItems={gendersOptionChoices}
                              selectedOptionId={contextState.user.gender_id}
                              setSelectedOption={(
                                optionChoice: OptionChoice
                              ) => {
                                setFieldValue(
                                  "gender",
                                  optionChoice.option_choice_name
                                );
                                contextDispatch({
                                  type: "updateUser",
                                  payload: {
                                    gender_id: optionChoice.option_choice_id,
                                  },
                                });
                              }}
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs mt-2"
                              name="gender"
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="pt-12 prose prose-sky prose-lg text-zinc-500 mx-auto">
                        <span className="block text-base text-center text-strata-blue font-semibold tracking-wide uppercase mb-10">
                          <Button text="Next" type="submit" size="lg" />
                        </span>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Timer />
      </div>
    );
  }
}
