import React, { useState, useContext, useEffect, useMemo } from "react";
import ResponseType from "../components/ResponseType";
import Button from "../components/Button";
import { Question, OptionChoice } from "../types";
import SurveyContext from "../context/surveyContext";
import SideDecorations from "../components/SideDecorations";
import { useNavigate, useParams } from "react-router-dom";
import { scroller } from "react-scroll";
import Spinner from "../components/Spinner";
import Timer from "../components/Timer";

const SurveyBody: React.FC = (props) => {
  const params = useParams();

  const navigationPage = parseInt(params.currentPage as string);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(navigationPage);
  const [error, setError] = useState("");
  const [optionChoices, setOptionChoices] = useState<{
    [key: number]: Array<OptionChoice>;
  }>({});
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const { contextState, contextDispatch } = useContext(SurveyContext);

  const questions = contextState.survey.surveyQuestions;

  function scrollTo(id: string) {
    scroller.scrollTo(id, {
      duration: 400,
      delay: 0,
      smooth: true,
      offset: -100,
    });
  }

  // gets an array of unique page numbers from all the questions
  const pages = [
    ...Array.from(new Set(questions.map((question) => question.page_number))),
  ];

  // gets an array of unique optionGroupId's from all the questions
  const optionGroupIds = useMemo(
    () => [
      ...Array.from(
        new Set(questions.map((question) => question.option_group_id))
      ),
    ],
    [questions]
  );

  async function getOptionChoices(optionGroupId: number) {
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/survey/optionChoices/${optionGroupId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: contextState.user.survey_key,
          },
        }
      )
        .then((res) => res.json())
        .then((optionChoices) => {
          setOptionChoices((prev) => ({
            ...prev,
            [optionGroupId]: optionChoices,
          }));
        });
    } catch (err) {
      console.error(err);
    }
  }


  useEffect(() => {
    window.onpopstate = null;

    setCurrentPage(navigationPage);

    const nextUnansweredQuestionIds =
        contextState.survey.surveyQuestions
          .filter((q) => q.page_number <= navigationPage)
          .map((q) => q.question_id)
          .filter(
            (q) =>
              !contextState.responses
                .map((r) => r.questionId)
                .includes(q)
          );
  setUnansweredQuestionIds(nextUnansweredQuestionIds);

    // if there is no survey information (proabably due to a refresh), send them to the home page
    if (contextState.survey?.surveyInformation?.survey_id === 0) {
      navigate("/");
    }

    // if there are no questions (proabably due to a refresh), send them to the home page
    if (contextState.survey?.surveyQuestions?.length === 0) {
      navigate("/");
    }
  }, [navigationPage]);

  useEffect(() => {
    // gets the option choices for each option group id
    for (let i = 0; i < optionGroupIds.length; i++) {
      getOptionChoices(optionGroupIds[i]);
    }
  }, [
    contextState.survey?.surveyInformation?.survey_id,
    contextState.survey?.surveyQuestions?.length,
    optionGroupIds,
  ]);

  const currentQuestions = questions.filter(
    (q) => q.page_number === currentPage
  );

  const pastQuestionCount = questions.filter(
    (q) => q.page_number < currentPage
  ).length;

  const [unansweredQuestionIds, setUnansweredQuestionIds] = useState<Array<number>>(
      contextState.survey.surveyQuestions
      .filter((q) => q.page_number <= currentPage)
      .map((q) => q.question_id)
      .filter(
        (q) =>
          !contextState.responses
            .map((r) => r.questionId)
            .includes(q)
    ));


  async function nextOrSubmit() {
    if (unansweredQuestionIds.length > 0) {
      setError("Please answer all the questions");
      scrollTo(
        `question-${
          pastQuestionCount +
          currentQuestions
            .map((q) => q.question_id)
            .indexOf(unansweredQuestionIds[0])
        }`
      );
      setCurrentQuestionIndex(
        pastQuestionCount +
          currentQuestions
            .map((q) => q.question_id)
            .indexOf(unansweredQuestionIds[0])
      );
    }
    // if not the last section, go to the next section
    else if (currentPage < pages.length) {
      navigate(`/survey/${currentPage + 1}`);
      setError("");
      setCurrentPage(currentPage + 1);
      setCurrentQuestionIndex(pastQuestionCount + currentQuestions.length);
      scrollTo("question-0");
    } else {
      setSubmitting(true);
      try {
        // post responses to server
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/survey/responses`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: contextState.user.survey_key,
            },
            body: JSON.stringify(contextState),
          }
        );

        if (response.status === 200) {
          contextDispatch({ type: "clearContext", payload: {} });
          window.onbeforeunload = null;
          navigate("/finished");
        } else {
          //error handling
          console.error(response);
          let body = await response.json();
          console.error(body);
          alert(body.errors[0]);
        }
      } catch (err) {
        console.error(err);
      }
      setSubmitting(false);
    }
  }

  // if the optionChoices haven't been loaded yet, display the loading spinner
  if (Object.keys(optionChoices).length !== optionGroupIds.length) {
    return <Spinner />;
  } else
    return (
      <div className="relative py-20 bg-white overflow-hidden">
        <SideDecorations />
        {currentQuestions.map((question: Question, index: number) => (
          <div
            id={`question-${pastQuestionCount + index}`}
            key={index}
            className={`relative px-4 sm:px-6 lg:px-8 ${
              pastQuestionCount + index === currentQuestionIndex
                ? "opactiy-100"
                : "opacity-25"
            }`}
          >
            <div className="mx-auto">
              <div>
                <h1>
                  <span className="block text-base text-center text-strata-blue font-semibold tracking-wide uppercase">
                    Question {pastQuestionCount + index + 1} of{" "}
                    {questions.length}
                  </span>
                </h1>
              </div>
              <div className="mb-20">
                <ResponseType
                  optionChoices={optionChoices[question.option_group_id]}
                  question={question}
                  selectedOptionId={
                    contextState.responses.find(
                      (r) => r.questionId === question.question_id
                    )?.optionChoiceId
                  }
                  setSelectedOption={(optionChoiceId: number) => {
                    contextDispatch({
                      type: "updateResponse",
                      payload: {
                        questionId: question.question_id,
                        optionChoiceId,
                      },
                    });
                    index === currentQuestions.length - 1
                      ? scrollTo("bottom")
                      : scrollTo(`question-${pastQuestionCount + index + 1}`);
                    setCurrentQuestionIndex(pastQuestionCount + index + 1);
                    const nextUnansweredQuestionIds =
                      contextState.survey.surveyQuestions
                        .filter((q) => q.page_number <= currentPage)
                        .map((q) => q.question_id)
                        .filter(
                          (q) =>
                            !contextState.responses
                              .map((r) => r.questionId)
                              .includes(q)
                        );
                    setUnansweredQuestionIds(nextUnansweredQuestionIds);
                    if (nextUnansweredQuestionIds.length === 0) {
                      setError("");
                    }
                  }}
                />
              </div>
            </div>
          </div>
        ))}

        <div
          id="bottom"
          className="relative px-4 sm:px-6 lg:px-8 text-center mb-1"
        >
          <div className="text-red-500 mb-5">{error}</div>
          <Button
            onClick={nextOrSubmit}
            text={currentPage < pages.length ? "Next" : "Finish"}
            size="lg"
            submitting={submitting}
          />
        </div>
        <Timer />
      </div>
    );
};

export default SurveyBody;
